import { useAnalyticsEmitter } from '@utils/analytics/emitter';
import { getInitials } from '@utils/strings';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useDebounce } from 'use-debounce';

import { useSubscriptionsManager } from '../../../../../features/auth/hooks/useSubscriptionsManager';
import {
  GeneratedSubscriptionType,
  useGetArtistsByIdsQuery,
  useSearchArtistsQuery,
} from '../../../../../types/generated';
import { Option } from '../../../../FramingGuide/Search/SearchResults';
import { ListItem, Search } from '../../../../Onboarding/Form/Search';

export default function ArtistSearch() {
  const [query, setQuery] = useState('');
  const [missingSubscriptions, setMissingSubscriptions] =
    useState<ListItem[]>();
  const { t } = useTranslation('onboarding');
  const [debouncedQuery] = useDebounce(query, 500);
  const { getSubscriptions, isSubscribedTo, subscribe } =
    useSubscriptionsManager();
  const [emit] = useAnalyticsEmitter();

  const { data: artistSearchResults } = useSearchArtistsQuery({
    variables: {
      query: debouncedQuery,
    },
  });

  const artistSearchSuggestions: Option[] =
    artistSearchResults?.searchArtists.slice(0, 3).map((artist) => ({
      title: artist.name,
      id: artist.id,
      variant: 'artist',
      thumbnail: artist.thumbnail,
    })) || [];

  const artistIds = t('artist.featured').split(';');

  const { data: featuredArtists } = useGetArtistsByIdsQuery({
    variables: {
      ids: artistIds,
    },
  });

  const getMissingSubscriptions = useCallback(async () => {
    if (!featuredArtists?.artists || featuredArtists.artists.length === 0)
      return;
    const subscriptions = await getSubscriptions(
      featuredArtists.artists.map((artist) => ({
        ref: '',
        type: GeneratedSubscriptionType.Artist,
        name: artist.name,
        objectId: artist.id,
      })),
    );
    const subscriptionIds = subscriptions.map((sub) => sub.objectId);
    setMissingSubscriptions(
      featuredArtists.artists
        .filter((artist) => !subscriptionIds.includes(artist.id))
        .map((artist) => {
          return {
            ...artist,
            thumbnail: {
              src: artist.thumbnail !== null ? artist.thumbnail : undefined,
              alt: getInitials(artist.name, 2),
            },
            selected: true,
          };
        })
        .sort((a, b) => artistIds.indexOf(a.id) - artistIds.indexOf(b.id))
        .splice(0, 5),
    );
  }, [featuredArtists?.artists, isSubscribedTo]);

  useEffect(() => {
    getMissingSubscriptions();
  }, [getMissingSubscriptions]);

  const handleSubmit = useCallback((input: Record<string, ListItem>) => {
    if (Object.values(input).length === 0) return;
    subscribe(
      Object.values(input)
        .filter((artist) => artist.selected)
        .map((artist) => ({
          name: artist.name,
          objectId: artist.id,
          type: GeneratedSubscriptionType.Artist,
          ref: '',
        })),
    );
    emit('', 'clicked', {
      artistsSelected: Object.values(input).map(({ name }) => name),
    });
  }, []);

  return (
    <Search
      cta="save"
      featuredItems={missingSubscriptions || []}
      onChange={setQuery}
      onSubmit={handleSubmit}
      placeholder={t('searchPlaceholder')}
      query={debouncedQuery}
      reverse
      suggestions={artistSearchSuggestions}
      title="artist.title"
    />
  );
}
