import { Icon } from '@design-system';
import { getInitials } from '@utils/strings';
import classNames from 'classnames';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { Image } from '../../Image';

export type Option = {
  id: string;
  description?: string;
  thumbnail?: string | null;
  href?: string;
  title: string;
  variant: 'city' | 'country' | 'search' | 'store' | 'artist' | 'add-new';
};

export type SearchResultsProps = {
  bordered: boolean;
  onEmptyLink?: () => void;
  options: Option[];
  onSelect: (option: Option, position: number) => void;
  query?: string;
  cursor: number;
};

const Thumbnail = ({ thumbnail, title, variant }: Partial<Option>) => {
  let icon = <Icon name="ic_location" />;
  if (variant === 'country') icon = <Icon name="ic_public" />;
  if (variant === 'search') icon = <Icon name="ic_search" />;
  if (variant === 'store') icon = <Icon name="ic_store" />;
  if (variant === 'add-new') icon = <Icon name="ic_add" />;
  if (variant === 'artist' && title) {
    icon = <span>{getInitials(title, 2)}</span>;
  }
  return (
    <div
      className={classNames(
        'flex items-center justify-center h-11 shrink-0 w-11',
        'bg-neutral-1 fill-neutral-4 overflow-hidden',
        variant === 'add-new' ? 'rounded-full' : 'rounded-md',
      )}
    >
      {thumbnail ? (
        <Image alt={title || ''} src={thumbnail} width={64} />
      ) : (
        icon
      )}
    </div>
  );
};

export const Option = (props: { option: Option }) => {
  const { option } = props;
  return (
    <>
      <Thumbnail
        thumbnail={option.thumbnail}
        title={option.title}
        variant={option.variant}
      />
      <div className="flex flex-col min-w-0 group-hover:text-neutral-4 transition-colors duration-300">
        <div className="truncate">{option.title}</div>
        {option.description && (
          <div className="truncate text-neutral-4">{option.description}</div>
        )}
      </div>
    </>
  );
};

export const OptionButton = ({
  cursor,
  index,
  onSelect,
  option,
}: {
  option: Option;
  cursor: number;
  index: number;
  onSelect: (option: Option, position: number) => void;
}) => {
  return (
    <button
      className={classNames('flex items-center gap-3 group', {
        'bg-neutral-1 rounded-md': cursor === index,
      })}
      onClick={() => onSelect(option, index)}
      type="button"
    >
      <Option option={option} />
    </button>
  );
};

export const SearchResults = ({
  bordered,
  cursor,
  onEmptyLink,
  onSelect,
  options = [],
  query,
}: SearchResultsProps) => {
  const { t } = useTranslation('framing');

  if (options.length === 0) {
    return null;
  }

  return (
    <div
      className={classNames(
        'bg-light rounded-sm shadow-card',
        'flex flex-col gap-3 p-4',
        {
          'border-none boxShadow-card': !bordered,
          'border border-divider/20': bordered,
        },
      )}
    >
      {options.map((option, index) =>
        option.href ? (
          <Link
            key={`${option.id}-${option.href}`}
            className={classNames('flex items-center gap-3 group', {
              'bg-neutral-1 rounded-md': cursor === index,
            })}
            href={option.href}
            onClick={() => onSelect(option, index)}
            onKeyDown={() => onSelect(option, index)}
            passHref
            role="link"
            tabIndex={0}
          >
            <Option option={option} />
          </Link>
        ) : (
          <OptionButton
            cursor={cursor}
            index={index}
            onSelect={onSelect}
            option={option}
          />
        ),
      )}
      {query ? (
        <OptionButton
          cursor={cursor}
          index={options.length}
          onSelect={onSelect}
          option={{ id: query, title: query, variant: 'add-new' }}
        />
      ) : null}
      {options[0].variant === 'search' && onEmptyLink && (
        <div className="flex flex-col md:flex-row items-center h-12 justify-center text-neutral-4">
          <span className="mr-1">{t('searchEmptyMessage')}</span>
          <Link
            className="group underline"
            href={t('searchEmptyHref')}
            onClick={onEmptyLink}
            onKeyDown={onEmptyLink}
            passHref
            role="button"
            tabIndex={0}
            target="_blank"
          >
            {t('searchEmptyLink')}
          </Link>
        </div>
      )}
    </div>
  );
};
