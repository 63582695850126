import { Icon } from '@design-system';
import classNames from 'classnames';
import { useMemo } from 'react';

interface Option {
  label: string | React.ReactNode;
  labelSuffix?: string | React.ReactNode;
  description?: string | React.ReactNode;
  checked: boolean;
  value: string;
  onChange: (val: string) => void;
  thumbnail?: {
    src?: string;
    alt: string;
  };
  gallery?: {
    src: string;
    alt: string;
  }[];
}

interface OptionCheckbox extends Option {
  type: 'checkbox';
}

interface OptionRadio extends Option {
  name: string;
  type: 'radio';
}

export const InputCard = (props: OptionCheckbox | OptionRadio) => {
  const {
    checked,
    description,
    gallery,
    label,
    labelSuffix,
    onChange,
    thumbnail,
    type,
    value,
  } = props;

  return (
    <label
      className={classNames(
        'flex border transition duration-300 hover:cursor-pointer hover:text-neutral-4 focus-within:outline-none focus-within:ring-2 focus-within:ring-divider/20 rounded-sm overflow-hidden text-sm-mobile sm:text-sm',
        { 'border-divider/20 ': !checked },
        { 'border-neutral-6': checked },
      )}
    >
      <div className="flex flex-col w-full">
        <div className="flex">
          {thumbnail ? (
            <div className="bg-neutral-1 text-neutral-4 flex items-center justify-center w-[52px] self-stretch">
              {thumbnail.src ? (
                <img
                  alt={thumbnail.alt}
                  className="h-full object-cover"
                  src={thumbnail.src}
                />
              ) : (
                <span>{thumbnail.alt}</span>
              )}
            </div>
          ) : null}
          <div
            className={classNames(
              'flex flex-1 text-left justify-between focus:outline-none p-4 md:p-4 gap-3 flex-row-reverse w-full',
            )}
          >
            <div>
              <div
                className={classNames(
                  'flex items-center justify-center h-5 w-5',
                  checked
                    ? 'bg-neutral-6 text-white'
                    : 'border border-divider/20',
                  type === 'radio' ? 'rounded-full' : 'rounded-sm',
                )}
              >
                <input
                  checked={checked}
                  className="absolute opacity-0 peer"
                  id={value}
                  // this rule contradicts discriminated type union
                  // eslint-disable-next-line react/destructuring-assignment
                  name={type === 'radio' ? props.name : value}
                  onChange={() => {
                    onChange(value);
                  }}
                  type={type}
                  value={value}
                />
                <Icon
                  className="invisible peer-checked:visible"
                  name="ic_check"
                  size="xs"
                />
              </div>
            </div>
            <div className="w-full">
              <div className="flex place-content-between">
                {typeof label === 'string' ? <p>{label}</p> : label}
                {typeof labelSuffix === 'string' ? (
                  <p>{labelSuffix}</p>
                ) : (
                  labelSuffix
                )}
              </div>
              {typeof description === 'string' ? (
                <p className="text-neutral-4">{description}</p>
              ) : (
                description
              )}
            </div>
          </div>
        </div>
        {checked && gallery ? (
          <div className="flex gap-3 px-4 pb-4">
            {gallery.map((image) => (
              <div
                key={image.src}
                className="basis-1/2 rounded-sm overflow-hidden"
              >
                <img alt={image.alt} src={image.src} />
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </label>
  );
};

interface CardGroupProps {
  legend: string;
  name?: string;
  options: Option[];
}

export const RadioInputGroup = ({ legend, name, options }: CardGroupProps) => {
  const inputName = useMemo(() => name || crypto.randomUUID(), [name]);

  return (
    <fieldset className="flex flex-col gap-3">
      <legend className="sr-only">{legend}</legend>
      {options.map((option) => (
        <InputCard
          key={option.value}
          type="radio"
          {...option}
          name={inputName}
        />
      ))}
    </fieldset>
  );
};

export const CheckboxInputGroup = ({ legend, options }: CardGroupProps) => {
  return (
    <fieldset className="flex flex-col gap-3">
      <legend className="sr-only">{legend}</legend>
      {options.map((option) => (
        <InputCard key={option.value} type="checkbox" {...option} />
      ))}
    </fieldset>
  );
};
