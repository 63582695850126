import React, { useCallback } from 'react';

import { SuccessContainer } from '../../../../../features/auth/containers';
import { FlowConfig } from '../../../../../providers';
import {
  GeneratedGetQuestionnaireQuery,
  GeneratedUpdateQuestionnaireInput,
  GetQuestionnaireDocument,
  useGetQuestionnaireQuery,
  useUpdateQuestionnaireMutation,
} from '../../../../../types/generated';
import { MultiChoice } from '../../../../Onboarding/Form/MultiChoice';
import { SingleChoice } from '../../../../Onboarding/Form/SingleChoice';
import { Intro } from '../../../../Onboarding/Intro';
import ArtistSearch from './ArtistSearch';
import { AuthEntryPoint } from '../../../../../features/auth/types';

export const budgetOptions = [
  {
    label: 'budget.options.under250',
    value: 'UNDER_250',
  },
  {
    label: 'budget.options.under500',
    value: 'UNDER_500',
  },
  {
    label: 'budget.options.under1000',
    value: 'UNDER_1000',
  },
  {
    label: 'budget.options.under2500',
    value: 'UNDER_2500',
  },
  {
    label: 'budget.options.under5000',
    value: 'UNDER_5000',
  },
  {
    label: 'budget.options.under10000',
    value: 'UNDER_10000',
  },
  {
    label: 'budget.options.over10000',
    value: 'OVER_10000',
  },
];

export const collectingExperienceOptions = [
  {
    label: 'collecting.options.neverBought',
    value: 'NEVER',
  },
  {
    label: 'collecting.options.startingOut',
    value: 'STARTING',
  },
  {
    label: 'collecting.options.occasional',
    value: 'YEARLY',
  },
  {
    label: 'collecting.options.fewTimes',
    value: 'OCCASIONALLY',
  },
  {
    label: 'collecting.options.onceInMonth',
    value: 'MONTHLY',
  },
];

export const typeOfArtOptions: Array<{
  label: string;
  name: keyof NonNullable<GeneratedGetQuestionnaireQuery['getQuestionnaire']>;
}> = [
  {
    label: 'type.options.limitedEditionPrints',
    name: 'limitedPrints',
  },
  {
    label: 'type.options.nfts',
    name: 'digital',
  },
  {
    label: 'type.options.collectibles',
    name: 'collectibles',
  },
  {
    label: 'type.options.paintings',
    name: 'paintings',
  },
  {
    label: 'type.options.sculptures',
    name: 'sculptures',
  },
  {
    label: 'type.options.photography',
    name: 'photography',
  },
  {
    label: 'type.options.drawings',
    name: 'drawings',
  },
];

const Form = ({
  children,
  field,
  fields,
}: {
  field?: 'frequency' | 'budget';
  fields?: typeof typeOfArtOptions;
  children: React.ReactElement;
}) => {
  const [updateQuestionnaire] = useUpdateQuestionnaireMutation({
    refetchQueries: [
      {
        query: GetQuestionnaireDocument,
      },
    ],
  });
  const { data: questionnaire } = useGetQuestionnaireQuery();

  const handleUpdateQuestionnaire = useCallback(
    async (input: GeneratedUpdateQuestionnaireInput) => {
      updateQuestionnaire({ variables: { input } });
    },
    [updateQuestionnaire],
  );

  const selectedOptions = fields
    ?.map((option) => option.name)
    .reduce(
      (curr, next) => {
        if (questionnaire?.getQuestionnaire?.[next])
          curr[next] = questionnaire?.getQuestionnaire?.[next];
        return curr;
      },
      {} as Record<string, unknown>,
    );

  if (!children) return null;
  return React.cloneElement(children, {
    onSubmit: handleUpdateQuestionnaire,
    values: selectedOptions,
    value: field ? questionnaire?.getQuestionnaire?.[field] : undefined,
  });
};

export type AnalyticsProperties = {
  page: AuthEntryPoint;
  id: string;
};

export const buildOnboardingFlow = (
  analyticsProperties?: AnalyticsProperties,
  isStarted?: boolean,
): FlowConfig => {
  return {
    name: 'onboarding',
    isProgressIndicatorVisible: true,
    steps: [
      {
        hideHeader: true,
        id: 'intro',
        hideSpacing: true,
        hideCloseButton: true,
        analytics: {
          name: 'onboarding start',
          properties: analyticsProperties,
        },
        content: (
          <Form>
            <Intro isStarted={isStarted} />
          </Form>
        ),
      },
      {
        hideSpacing: true,
        id: 'collecting-experience',
        analytics: {
          name: 'onboarding collecting',
          properties: analyticsProperties,
        },
        content: (
          <Form field="frequency">
            <SingleChoice
              cta="next"
              name="frequency"
              options={collectingExperienceOptions}
              title="collecting.title"
              classNames={{
                title: 'md:text-md text-center px-3',
                wrapper: 'px-6 pb-6',
              }}
            />
          </Form>
        ),
      },
      {
        hideSpacing: true,
        id: 'type-of-arts',
        analytics: {
          name: 'onboarding mediums',
          properties: analyticsProperties,
        },
        content: (
          <Form fields={typeOfArtOptions}>
            <MultiChoice
              cta="next"
              options={typeOfArtOptions}
              title="type.title"
              classNames={{
                title: 'md:text-md text-center px-3',
                wrapper: 'px-6 pb-6',
              }}
            />
          </Form>
        ),
      },
      {
        hideSpacing: true,
        id: 'budget',
        analytics: {
          name: 'onboarding budget',
          properties: analyticsProperties,
        },
        content: (
          <Form field="budget">
            <SingleChoice
              cta="next"
              name="budget"
              options={budgetOptions}
              title="budget.title"
              classNames={{
                title: 'md:text-md text-center px-3',
                wrapper: 'px-6 pb-6',
              }}
            />
          </Form>
        ),
      },
      {
        hideSpacing: true,
        id: 'artist-search',
        analytics: {
          name: 'onboarding artists',
          properties: analyticsProperties,
        },
        content: <ArtistSearch />,
      },
      {
        id: 'success',
        content: <SuccessContainer />,
        hideBackButton: true,
        isProgressIndicatorVisible: false,
        analytics: {
          name: 'onboarding complete',
          properties: analyticsProperties,
        },
      },
    ],
  };
};
