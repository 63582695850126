import { useCallback, useEffect, useMemo, useState } from 'react';
import Cookies from 'universal-cookie';

import {
  AnalyticsProperties,
  buildOnboardingFlow,
} from '../components/Containers/Dialog/Flows/Onboarding';
import { useFlow } from '../providers';
import { useGetQuestionnaireQuery } from '../types/generated';
import { useQueryParams } from './useQueryParams';
import { buildSignInFlow } from '../features/auth';
import { AuthFlow } from '../features/auth/types';

const loggedInCookie = (
  process.env.NEXT_PUBLIC_API_ENDPOINT as string
)?.includes('staging')
  ? 'aa-logged-in-dev'
  : 'aa-logged-in';

const uc = new Cookies();

export function useOnboarding() {
  const params: { showOnboarding?: string } = useQueryParams();
  const showOnboardingParam = params.showOnboarding === 'true';
  const isLoggedIn = Boolean(uc.getAll()[loggedInCookie]);

  const { data: questionnaire, refetch } = useGetQuestionnaireQuery({
    skip: !isLoggedIn,
  });
  const isOnboardingStarted = Boolean(questionnaire?.getQuestionnaire);

  const [showOnboarding, setShowOnboarding] = useState<
    | {
        analytics?: AnalyticsProperties;
      }
    | undefined
  >(showOnboardingParam && isLoggedIn ? {} : undefined);

  const [showSignIn, setShowSignIn] = useState<boolean>(
    !isLoggedIn && showOnboardingParam,
  );
  const { startFlow } = useFlow();

  const start = useCallback(() => {
    startFlow(buildOnboardingFlow(showOnboarding?.analytics));
  }, [showOnboarding?.analytics, startFlow]);

  const progress = useMemo(() => {
    let completed = 0;
    const total = 3;
    const data = questionnaire?.getQuestionnaire;

    if (data?.frequency) completed += 1;
    if (
      data?.limitedPrints ||
      data?.limitedSculptures ||
      data?.digital ||
      data?.collectibles ||
      data?.paintings ||
      data?.drawings ||
      data?.photography ||
      data?.sculptures
    )
      completed += 1;
    if (data?.budget) completed += 1;

    return Math.floor((completed / total) * 100);
  }, [questionnaire?.getQuestionnaire]);

  useEffect(() => {
    if (showSignIn) {
      startFlow({
        name: AuthFlow.SignIn,
        onFlowComplete: () => setShowOnboarding({}),
        steps: buildSignInFlow({ hideSuccessScreen: true }),
      });
      setShowSignIn(false);
      return;
    }
    /**
     * Display onboarding if all are true
     * 1. User is logged in
     * 2. onboarding is enabled (`showOnBoarding` state is true)
     * 3. onboarding hasn't started yet OR user comes from a marketing email in which case it's irrelevant whether it's started or not
     */
    if (
      isLoggedIn &&
      (showOnboardingParam || !isOnboardingStarted) &&
      showOnboarding
    ) {
      start();
      setShowOnboarding(undefined);
    }
  }, [
    isOnboardingStarted,
    showOnboarding,
    startFlow,
    isLoggedIn,
    showOnboardingParam,
    start,
  ]);

  const show = useCallback((analyticsProperties: AnalyticsProperties) => {
    setShowOnboarding({ analytics: analyticsProperties });
  }, []);

  useEffect(() => {
    if (isLoggedIn) refetch();
  }, [isLoggedIn]);

  return {
    isOnboardingStarted,
    show,
    progress,
    start,
    refetch,
  };
}
