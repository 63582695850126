import { Button } from '@design-system';
import { getInitials } from '@utils/strings';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useMemo, useState } from 'react';

import { InputCard } from '../../../../design-system/components/Fields/RadioButton/RadioCard';
import { useFlow } from '../../../../providers';
import { Search as FramingGuideSearch } from '../../../FramingGuide/Search/Search';
import { Option } from '../../../FramingGuide/Search/SearchResults';

export interface ListItem {
  name: string;
  id: string;
  thumbnail?: {
    src?: string;
    alt: string;
  };
  selected?: boolean;
}

interface Props {
  title: string;
  cta: string;
  featuredItems: ListItem[];
  onChange: (val: string) => void;
  query: string;
  suggestions: Option[];
  placeholder?: string;
  onSubmit?: (input: Record<string, ListItem>) => void;
  values?: Record<string, ListItem>;
  reverse?: boolean;
}

const List = ({
  items,
  selected,
  setSelected,
}: {
  setSelected: React.Dispatch<
    React.SetStateAction<Record<string, ListItem | undefined>>
  >;
  selected: Record<string, ListItem | undefined>;
  items: ListItem[];
}) => {
  if (!items) return null;
  const list = items.map((item) => (
    <InputCard
      key={item.id}
      checked={Boolean(selected[item.id]?.selected)}
      label={item.name}
      onChange={() => {
        setSelected((prev) => {
          return {
            ...prev,
            [item.id]: {
              ...item,
              selected: Boolean(!prev[item.id]?.selected),
            },
          };
        });
      }}
      thumbnail={item.thumbnail}
      type="checkbox"
      value={item.id}
    />
  ));

  return <>{list}</>;
};

export const Search = ({
  cta,
  featuredItems,
  onChange,
  onSubmit,
  placeholder,
  query,
  reverse,
  suggestions,
  title,
  values,
}: Props) => {
  const { t } = useTranslation('onboarding');
  const [selected, setSelected] = useState<Record<string, ListItem>>(
    values || {},
  );
  const { goNextStep } = useFlow();

  useEffect(() => {
    if (values && Object.keys(selected).length === 0) setSelected(values);
  }, [selected, values]);

  const featuredItemIds = featuredItems.map((item) => item.id);

  const selectedListItems = Object.values(selected).filter(
    (item) => !featuredItemIds.includes(item.id),
  );

  const list = useMemo(() => {
    return [
      ...(reverse ? selectedListItems.reverse() : selectedListItems),
      ...featuredItems,
    ];
  }, [featuredItemIds, featuredItems, selected]);

  const handleNext = () => {
    if (onSubmit) onSubmit(selected);
    goNextStep();
  };

  return (
    <div className="flex flex-col px-6 pb-6 gap-6 text-center">
      <div className="flex flex-col gap-3 px-3">
        <h2 className="md:text-md font-bold">{t(title)}</h2>
      </div>
      <div className="flex flex-col gap-3">
        <FramingGuideSearch
          className="w-full"
          onChange={(val) => onChange(val)}
          onSelect={(option) => {
            setSelected((prevState) => ({
              ...prevState,
              [option.id]: {
                ...option,
                name: option.title,
                thumbnail: {
                  src: option.thumbnail !== null ? option.thumbnail : undefined,
                  alt: getInitials(option.title, 2),
                },
                selected: true,
              },
            }));
          }}
          onSubmit={() => {}}
          placeholder={placeholder}
          query={query}
          suggestions={suggestions}
        />
        <div className="max-h-[336px] overflow-y-scroll no-scrollbar">
          <div className="flex flex-col gap-3">
            <List items={list} selected={selected} setSelected={setSelected} />
          </div>
        </div>
      </div>
      <Button
        className="sticky"
        disabled={!selected}
        fullWidth
        label={t(cta)}
        onClick={handleNext}
        size="lg"
        type="submit"
        variant="primary"
      />
    </div>
  );
};
