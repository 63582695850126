import { Button } from '@design-system';
import classNames from 'classnames';
import React from 'react';

import { useMediaQuery } from '../../../../hooks/useMediaQuery';
import { Step } from '../../../../providers';

interface Props {
  hidden: boolean;
  id: string;
  step: Step;
  isBackBtnVisible?: boolean;
  isFirstStep: boolean;
  stepCount: number;
  currentStepIndex: number;
  onStepBack: () => void;
  onClose: () => void;
  isCloseBtnVisible?: boolean;
  isProgressIndicatorVisible?: boolean;
}

export const ProgressIndicator = ({
  currentStepIndex,
  stepCount,
}: Pick<Props, 'stepCount' | 'currentStepIndex'>) => {
  return (
    <ol
      aria-label="Steps to complete the dialog"
      className="flex gap-2 py-1.5 justify-center"
    >
      {/** We assume the last step is always a confirmation / thank you step hence why we subtract 1 from `stepCount`  **/}
      {Array.from({ length: stepCount - 1 }).map((_, index) => (
        <li
          aria-label={`Step ${index} out of ${stepCount - 1}`}
          aria-current={currentStepIndex === index}
          className={classNames('w-1.5 h-1.5 rounded-full', {
            'bg-active-4': currentStepIndex === index,
            'bg-neutral-6': currentStepIndex > index,
            'bg-neutral-2': currentStepIndex < index,
          })}
        />
      ))}
    </ol>
  );
};

export const Header = (props: Props) => {
  const {
    currentStepIndex,
    hidden,
    id,
    isBackBtnVisible = true,
    isCloseBtnVisible = true,
    isFirstStep,
    isProgressIndicatorVisible,
    onClose,
    onStepBack,
    step,
    stepCount,
  } = props;
  const isMobile = useMediaQuery('sm');

  if (hidden) return null;

  return (
    <div
      className="w-full bg-light flex items-center justify-between md:border-none z-10 p-6 sticky top-0"
      id={id}
    >
      {!isBackBtnVisible || isFirstStep ? (
        <div className="w-9 h-0" />
      ) : (
        <Button
          label="Back"
          hideLabel
          icon="ic_arrow_left"
          size={isMobile ? 'xs' : 'sm'}
          variant="transparent"
          onClick={onStepBack}
        />
      )}
      {step.title && !isProgressIndicatorVisible ? (
        <p
          className={classNames(
            'bold font-bold grow text-center px-3',
            isFirstStep ? 'text-md' : 'sm:text-sm md:text-md',
          )}
        >
          {step.title}
        </p>
      ) : null}
      {!isProgressIndicatorVisible ? null : (
        <ProgressIndicator
          currentStepIndex={currentStepIndex}
          stepCount={stepCount}
        />
      )}
      {Boolean(!isCloseBtnVisible || step.hideCloseButton) ? null : (
        <Button
          label="Close"
          hideLabel
          icon="ic_close"
          size={isFirstStep || !isMobile ? 'sm' : 'xs'}
          variant="transparent"
          onClick={onClose}
        />
      )}
    </div>
  );
};
